@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.bpr_contactuspage{
    border-top: 4px solid #fab700;
    overflow-x: hidden;
    
    font-family: 'Montserrat', sans-serif;

}

.bpr_contactfirsthead{
    font-weight: 800;
    font-size: 30px;
    color: #fab700;
    letter-spacing: 1px;
   text-transform: uppercase;
   position: relative;
   margin-top: 10px;
   margin-bottom: 20px;
}
.bpr_contactfirsthead::after{
    content: "";
    position: absolute;
    bottom: -10%;
    left: 0%;
   
    height: 4px;
    background-color: #fab700;
    border-radius: 50px;
    animation: constcusaftercu 3s linear 1s infinite forwards;
}

@keyframes constcusaftercu {
    0%{
        width: 0%;
       
    }
    100%{
        width: 100%;
      
    }
}

.bpr_conatcusci{
   background-color: #16499c;
   padding: 140px 0px 140px 140px;
   margin: 20px 0px;
   border-radius: 10px;
   position: relative;
   /* margin-bottom: 100px; */
}
.bpr_formcard{
    position: absolute;
    left: 4%;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) ;
    margin-top: 20px;
}

.bpr_formconatcuscon{
    display: flex;
    flex-direction: column;
  

}

.bpr_conatctuspageinputs{
   margin: 5px 10px;
   outline: none;
   border: none;
   padding: 10px;
   background-color: transparent;
    border-bottom: 1.3px solid rgba(62, 62, 62, 0.877); 
   
}

.bpr_conatctuspageinputs::placeholder{
    color: rgba(62, 62, 62, 0.877);
    text-align: left;
}

.bpr_contactuspageh5{
    color: white;
    font-weight: 800;
    font-size: 25px;
    position: absolute;
    top: 30%;
    left: 11%;
    letter-spacing: 1px;
    text-transform: uppercase;
}


.bpr_footerbtnsub2{
    width: 40%;
    outline: none;
    background-color: #fab700;
    border-radius: 5px;
    border: none;
    text-align: center;
    margin: 10px 10px;
   
   padding: 10px 0px;
   color: white;
   font-weight: 500;
   text-transform: uppercase;
   letter-spacing: 2px;
   position: relative;
}

.bpr_footerbtnsub2::after{
content: "SUBMIT";
position: absolute;
height: 100%;
width: 0%;
top: 0;
left: 0;
background-color: #056ace;
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px;
opacity: 0;
transition: 0.5s;
}

.bpr_footerbtnsub2:hover::after{
    opacity: 1;
    width: 100%;
}

.bpr_conatcusfirstrow{
    position: relative;
}

.bpr_conatusimagesec{
    position: absolute;
    right: 20%;
    
}



.bpr_weloveimgbotttom{
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #16499c;
    font-size: 30px;
}


.bpr_welovepara{
    margin: 10px 0px 40px 0px;
    text-align: justify;
    letter-spacing: 1px;
    
}

.bpr_cardconatctusbottom{
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    margin-bottom: 15px;
    transition: 0.5s;
}

.bpr_cardconatctusbottom:hover{
    transform: scale(1.1);
}

.bpr_ourlocationscus{
    color: #002e5b;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
    margin-left: 10px;
    font-size: 23px;
    position: relative;
    
}
.bpr_ourlocationscus::after{
    content: "";
    position: absolute;
    background-color: #002e5b;
    width: 100%;
    height: 4px;
    left: 0%;
    bottom: -7%;
    border-radius: 50px;
    
}

.bpr_loactionifo{
    color: #fab700;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    padding: 10px 10px 10px 10px;
    letter-spacing: 1px;
}


.bpr_conatcusaddres{
    width: 100%;
    height: 100%;
    background-color: #002e5b;
    border-radius: 10px;
}

.bpr_haederloca{
    color: white;
    font-size: 20px;
    text-transform: uppercase;
     font-weight: 800;
     letter-spacing: 1px;
     padding: 20px 30px;
    
}

.bpr_conatcusul>li{
list-style-type: none;
padding: 10px;
color: white;
position: relative;
margin-left: 4px;
}

.bpr_conatcusulicon{
    position: absolute;
    left: -2%;
    top: 16%;
    color: #fab700;
    
}

.bpr_conatcusulicon2{
    position: absolute;
    left: -3%;
    top: 35%;
    color: #fab700;
    
}

.bpr_conatctusfooterpage{
    width: 100%;
    padding: 40px;
    background-color: #fab700;
}

.bpr_conatctusfooterpagehead{
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 10px;
    font-size: 20px;
}

.bpr_contuslastbtn{
    background-color: #002e5b;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    transition: 0.5s;
}

/* frontend training */

.bpr_fornendsec{
    border-top: 4px solid #fab700;
}

.bpr_contatussimges{
    width: 150%;
}

.bpr_contatussimges2{
    width: 160%;
}

.bpr_frontendworkhead{
    color: #fab700;
    font-weight: 800;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;

}

.bpr_frontendparasec{
    color: #002e5b;
    letter-spacing: 1px;
    text-align: justify;
}


.bpr_mapgif{
    width: 120%;
}

.bpr_frontendtech{
    padding-left: 10px;
}

.bpr_frontendtech>li{
    list-style-type: none;
    padding: 10px 0px;
    letter-spacing: 1px;
    font-weight: 700;
    color: #002e5b;
    font-size: 18px;
    text-transform: uppercase;
}

.bpr_frontendtechhead{
    color: #fab700;
    font-weight: 800;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.bpr_conatcusfirstrow2{
    margin-bottom: 150px;
}

.bpr_contatussimges3{
    width: 140%;
}
/* backendtrainee */


.bpr_backendtrainee{
    border-top: 4px solid #fab700;
}

.bpr_contatussimges3{
    width: 140%;
}


.bpr_modaltitlecona{
    color: #002e5b;
    font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* fullstack */

.bpr_Fullstack{
    border-top: 4px solid #fab700;
}


.bpr_contatussimges6{
    width: 200%;
  
}

.bpr_conatusimagesec2{
    position: absolute;
    right: 25%;
    top: 10%;
    
}


.bpr_footerbtnsub6{
    width: 90%;
    outline: none;
    background-color: #fab700;
    border-radius: 5px;
    border: none;
    text-align: center;
    margin: 10px 10px;
   
   padding: 10px 0px;
   color: white;
   font-weight: 500;
   text-transform: uppercase;
   letter-spacing: 2px;
   position: relative;
}

.bpr_footerbtnsub6::after{
content: "SUBMIT";
position: absolute;
height: 100%;
width: 0%;
top: 0;
left: 0;
background-color: #056ace;
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px;
opacity: 0;
transition: 0.5s;
}

.bpr_footerbtnsub6:hover::after{
    opacity: 1;
    width: 100%;
}

/* app developemt */
.bpr_Appdevtraine{
    border-top: 4px solid #fab700;
}
@media screen and (max-width:767px) {

    .bpr_conatusimagesec{
        top: 0%;
        left: 55%;
    }
    .bpr_contatussimges{
        width: 50%;
    }

    .bpr_conatcusfirstrow2{
        margin-bottom: 0px;
    }

   

    .bpr_contatussimges6{
    display: none;
    }

    .bpr_contatussimges2{
        width: 40%;
       
        margin-left: 20px;
    }

    .bpr_mapgif2{
    width: 90%;
    }


    .bpr_contatussimges3{
        width: 30%;
        margin-left: 40px;
    }
    /* .bpr_conatusimagesec{
        position: absolute;
        left: 48%;
        top: -5%;
        
    } */

    .bpr_formcard{
       margin-top: 50px;
        left: 5%;
        right: 5%;
    }
    

    .bpr_conatcusci{
        margin-bottom: 450px;
    }


    

    
}

.rw_constactusulsec{
    padding: 0px;
}

.rw_constactusulsec > li{
list-style: none;
padding: 10px 0px;
}

.rw_constactusulsec>li>a{
    text-decoration: none;
    color: #16499c;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 18px;

}


.bpr_conatcusfirstrow{
    margin-bottom: 100px;
}

.rw_contactivon{
    margin-right: 10px;
   
    color: #fab700;
   
    
}

@media screen and (max-width : 767px) {
    .bpr_conatcusfirstrow{
        margin-bottom: 0px;
    }
}

@media screen and (min-width:768px) and (max-width:992px) {
    .bpr_contatussimges{
        width: 220%;
    }
    .bpr_contatussimges2{
        width: 60%;
    }
    
    
}