/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 5px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg,#f04923,#164996);
}


.header-scrolled{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  z-index:999;
  background-color: #F6E9E9;
  box-shadow: .5084rem 1.1419rem 2.5rem 0 rgb(0 0 0 / 8%);
  transition:0.3s ease-in-out;
}





html{
  scroll-behavior: smooth;
}