@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.rwmainfootercon{
    background-color: #1B2430;
    padding: 20px 0px;
    border-top: 4px solid #FFC045;
}
footer{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.rw_logofooter{
    width: 80%;
}

.rw_footerinfopara{
    color: #aeaeae;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 14px;
   
}

.rw_footericonsul{
    margin-bottom: 0px;
    padding-left: 0px;
    display: flex;

}

.rw_footericonsul>li{
    list-style-type: none;
    padding: 0px 5px;
}

.rw_footericons{
    padding: 10px;
    color: #aeaeae;
    font-weight: 600;
    background-color: #272f39;
    border-radius: 50%;
    transition: 0.5s;
}

.rw_footericons:hover{
    transform: translateY(-5px);
    background-color: #FFC045;
    color: white;
}
.rw_footericons2{
    padding: 10px 11px;
    color: #aeaeae;
    font-weight: 600;
    background-color: #272f39;
    border-radius: 50%;
    transition: 0.5s;
    
}

.rw_footericons2:hover{
    transform: translateY(-5px);
    background-color: #FFC045;
    color: white;
}

.rw_footerhead{
    color:#aeaeae;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
}

.fw_hr{
    height: 4px;
    background-color:#aeaeae;
    border-radius: 50px;
}

.rw_footerinfopara2{
    color:#aeaeae;
    font-weight: 500;
    letter-spacing: 1px;
     position: relative;
     text-align: center;
}

.rw_loactiondot{
    position: absolute;
    left: 2%;
    top: 8%;
}

.rw_footerinfopara3{
    color:#aeaeae;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    text-align: center;
    transition: 0.5s;
}

.rw_footerinfopara3:hover{
    color: #FFC045;
}

.mapouter{position:relative;
    text-align:right;
    height:auto;
    width:auto;}
.gmap_canvas {overflow:hidden;background:none!important;height:auto;width:auto;}


.rw_quicklinks{
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
   
}

.rw_quicklinks>li{
    list-style-type: none;
    padding: 10px 0px;
}

.rw_quicklinks2{
    color:#aeaeae;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    text-align: center;
    transition: 0.5s;
}

.rw_quicklinks2:hover{
    color: #FFC045;
}

.rw_footerinputs{
    background-color: #272f39;
    width: 100%;
    outline: none;
    border: none;
    margin: 10px 0px;
    padding: 10px 10px;
    border-radius: 5px;
    color: #fff;
    transition: 0.5s;
}

.rw_footerinputs:focus{
    color:#aeaeae;
    
}

.rw_footerinputs::placeholder{
    color:#aeaeae;
  
}

.rw_footersubbtn{
    outline: none;
    width: 100%;
    padding: 10px;
    text-align: center;
    border: none;
    border-radius: 5px;
    background-color: #FFC045;
    color: #082032;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
}

.rw_footersubbtn::after{
    content: "submit";
    position: absolute;
  color:  #082032;
  background-color: #F6E9E9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0%;
  bottom: 0%;
  width: 0%;
  height: 100%;
  font-weight: 600;
  text-transform: uppercase;
  transition: 0.5s;
  opacity: 0;
}

.rw_footersubbtn:hover::after{
    width: 100%;
    opacity: 1;
}

.rw_footerinfopara4{
    color:#aeaeae;
    font-weight: 500;
    letter-spacing: 1px;
     position: relative;
     text-align: center;
}


.rw_footerinfopara4::after{
    content: "";
    position: absolute;
    left: 50%;
    bottom: -30%;
    width: 0%;
    height: 2px;
    background-color: #FFC045;
    border-radius: 0px 50px 50px 0px;
    transition: 0.5s;
    
    }
    
    .rw_footerinfopara4::before{
        content: "";
        position: absolute;
        right: 50%;
        bottom: -30%;
        width: 0%;
        height: 2px;
        background-color: #FFC045;
        border-radius: 50px 0px 0px 50px;
        transition: 0.5s;
        
        }
    
        .rw_footerinfopara4:hover::after{
            width: 50%;
        }
        .rw_footerinfopara4:hover::before{
            width: 50%;
        }

        #progress{
   
            position: fixed;
            bottom: 20px;
            right: 10px;
            width: 50px;
            height: 50px;
            display: none;
             place-items: center;
              border-radius: 50px;
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
              cursor: pointer;
          }
          #progressvalue{
            display: block;
            width: calc(100% - 15px);
            height: calc(100% - 15px);
            background-color: #fff;
            border-radius: 50%;
            display: grid;
            place-items: center;
            font-size: 34px;
            
          }


        @media screen and (max-width : 767px) {

            .rw_footerinfopara4:hover::after{
                display: none;
            }
            .rw_footerinfopara4:hover::before{
               display: none;
            }
            
        }