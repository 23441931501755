.rw_con3fisrthead{
    color: #FFC045;
    text-transform: uppercase;
   text-align: center;
    font-weight: 800;
    font-size: 25px;
    letter-spacing: 1px;
}

.rw_con3fisrthead2{
    
    text-transform: uppercase;
   text-align: center;
    font-weight: 800;
    font-size: 18px;
    letter-spacing: 1px;
}


.rw-servicescards{
    border: none;
    box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.3);
    margin: 15px 0px;
    position: relative;
    min-height: 230px;
    max-height: auto;
    /* transition: 0.5s; */
}
/* .rw-servicescards:hover{
    transform: scale(1.05);
} */



.rw-servicescards::after{
    content: "";
    position: absolute;
   
    width: 8%;
    height: 12%;
    left: 0%;
    bottom: 0%;
    border-left: 4px solid #164996;
    border-bottom: 4px solid #164996;
    
}

/* .rw-servicescards::before{
    content: "";
    position: absolute;
   
    width: 8%;
    height: 12%;
    right: 0%;
    bottom: 0%;
    border-bottom: 4px solid #FFC045;
    border-right: 4px solid #FFC045;
    
} */

.rw_cardicons{
    color: #FFC045;
    background-color: #164996;
    padding: 10px;
    position: absolute;
    top: -7%;
    right: 3%;
    border-radius: 50%;
}

.rw_serviceim{
    margin-top: 5px;
    height: 100px;
    width: 30%;
    overflow: hidden;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.rw-cardimgmain{
    width: 100%;
    height: auto;
}


.rw_cardtitle{
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    
}

.rw_contentcard{
    text-align: center;
    font-weight: 600;
    padding: 10px 0px;
    letter-spacing: 1px;
    
}
