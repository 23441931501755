@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.rw_homemaincon{
    padding: 15px 0px;
    font-family: 'Montserrat', sans-serif;

    
}

.rw_homehjeaderfirst{
    
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 30px;
}

.rw_homehjeaderfirstspan{
    color: #FFC045;
}

.rw_homehjeadersec{
  
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 25px;
}


.rw_homebtnfirst{
    border: none;
    background-color: #fff;
    color: #164996;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 10px 20px;
    font-weight: 500;
}


.bpr_ourstorylinkbtn{
    padding: 10px 20px;
    background-color: #fff;
    color: #164996;
    text-transform: uppercase;
   border: none;
   margin: 10px 0px;
   font-weight: 600;
   letter-spacing: 2px;
   box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.3);
position: relative;
 transition: 0.4s;
}

.bpr_ourstorylinkbtn:hover .bpr_newspanstory{
  
    z-index: 2;
    position: absolute;
    width: 100%;
    color: #164996;
    left: 0%;
    bottom: 0%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
}

.bpr_newspanstory{
    display: none;
}

.bpr_ourstorylinkbtn::after{
    content: "";
    position: absolute;
    left: 0%;
    bottom: 0%;
    height: 100%;
    width: 2%;
    background-color: #fab700;

    transition: 0.4s;
}
.bpr_ourstorylinkbtn:hover::after{
    width: 50%;   
}


 .bpr_ourstorylinkbtn::before{
    content: "";
    position: absolute;
    right: 0%;
    bottom: 0%;
    height: 100%;
    width: 2%;
    background-color: #fab700;
    transition: 0.4s;
} 

.bpr_ourstorylinkbtn:hover::before{
    width: 50%;
}


.rw_homehjeadersec2{
    text-align: justify;
    color: #FFC045;
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 25px;
}


.rw_homesecondconatiner{
    padding: 15px 0px;
    background-color: #fff;
    position: relative;
}

.rw_quoteright{
    position: absolute;
    top: 0%;
    left: 1%;
    color: #164996;
    font-size: 18px;
    font-weight: 600;
    background-color: #FFC045;
    padding: 10px;
}

.rw_quoteleft{
    position: absolute;
    bottom: 0%;
    right: 1%;
    color: #164996;
    font-size: 18px;
    font-weight: 600;
    background-color: #FFC045;
    padding: 10px;
    transform: rotate(360deg);
}

.rw_counterstarthead{
    color: #FFC045;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 40px;
    text-align: center;
}

.rw-countuppara{
    color: #164996;
    font-size: 25px;
}

.rw_homehjeadersecpara{
    
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 25px;
    
}

.rw-countuppara5{
    color: #fff;
    
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 18px;
}

.rw_counterimg{
    width: 15%;
    
}

.rw_svgcount{
    fill: #164996;
    width: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.rw_homeconatiner3{
    padding: 15px 0px;
    background-color: #fff;
}


.rw_knowabout{
    color: #FFC045;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 20px;
}

.rw_knowaboutpara{
    font-weight: 500;
    letter-spacing: 1px;
    text-align: justify;
}


.rw_vivionhead{
    
    font-weight: 700;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.rw_paravivion{
    font-weight: 500;
    letter-spacing: 1px;
    text-align: justify;
}

.rw_howitworks{
    color: #164996;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
}

.rw_paras{
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
}
.rw_workingstepul{
    padding-left: 10px;
}

.rw_workingstepul>li{
  list-style-type: none;
  padding: 10px 0px;
}


.rw_workingstepul>li>h5{
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 20px;
    color: #164996;
}

.rw_workingstepul>li>p{
    font-weight: 500;
    text-align: justify;
    letter-spacing: 1px;
}


.rw_cionsworks{
    font-size: 25px;
    color: #164996;
}

.rw_ppheading{
    color: #ff455b;
    font-weight: 1000;
    letter-spacing: 1px;
    font-size: 20pt;
}

.rw_ppheading1{
    color: #FFC045;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 20px;
}
.rw_ppheading2{
    color: #fccc6b;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 20px;
}

.rw_ppcontent{
    font-weight: 400;
    letter-spacing: 1px;
    text-align: justify;
}
