@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.Singuppage{
    font-family: 'Montserrat', sans-serif;
}
.rw_singuplogo{
    width: 50%;
}


.rw_singuphead{
margin: 15px 0px;

text-transform: uppercase;
font-weight: 800;
color: #16499c;
font-size: 32px;
letter-spacing: 1px;
}


.rw_singupheadersec{
    color: rgb(81, 80, 80);
    font-weight: 700;
    text-align: justify;
    text-transform: uppercase;
    margin: 10px 0px;
}

.rw_singupbtn{
    border: none;
    background-color: #16499c;
    color: #fff;
    font-weight: 500;
    letter-spacing: 2px;
    padding: 10px 30px;
    font-size: 16px;
    border-radius: 5px;
}

.Singuppage{
    position: relative;
    margin-bottom: 90px;
}

.rw_bgimgsingup{
    width: 20%;
    position: absolute;
    left: 0px;
   bottom: -20%;
   

}

.fomrsec{
    padding: 10px 25px;
    width: 70%;
    height: auto;
    background-color: #16499cea;
    display: block;
    margin-left: auto;
    
    border-radius: 10px;
    justify-content: center;
    margin-top: 15px;
}

.lablename{
    text-transform: capitalize;
    display: block;
    color: #fff;
    padding-bottom: 5px;
}

.rw_signupinputs{
    border-radius: 50px;
    outline: none;
    border: none;
    height: 30px;
    padding: 0px 10px;
    color: #16499c;
    width: 100%;
    display: block;
}

.rw_sinuppagesubmit{
    width: 100%;
    border: none;
  
    display: block;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    color: #fff;
background-color: #43fa00e0;
border-radius: 10px;
font-weight: 500;
letter-spacing: 1.5px;
text-transform: uppercase;

}

@media screen and (max-width: 767px) {
    .fomrsec{
        width: 100%;
    }


}

@media screen and (min-width : 767px) and (max-width : 992px) {
    .fomrsec{
        width: 100%;
    }
}
