.rw_firstheader{
    background-color: #164996;

}



.rw_firstheaderul{
    display: flex;
    width: 100%;
    margin-bottom: 0rem;
    justify-content: center;
    
}

.rw_firstheadericons{
    color: #FFC045;
    padding-right: 5px;
}

.rw_firstheaderul>li{
   color: #fff;
    padding: 5px 40px;
    list-style-type: none;
    font-size: 15px;
}

.rw_firstheaderul>li:nth-child(4){
    background-color: #FFC045;
    letter-spacing: 1px;
}

.rw_firstheadercontact{
    text-decoration: none;
    color: #fff;
    transition: 0.5s;
}

.rw_firstheadercontact:hover{
    color: #FFC045;
}

nav{
    display: flex;
}

.rw_nav{
    background-color: #F6E9E9;
    padding: 20px 0px 10px 0px;
    transition: 0.5s;
}

.rw_logonav{
    width: 15%;
}

.rw_navul{
    padding-bottom: 0px;
    display: flex;
    margin-bottom: 0px;

}

.rw_navul>li{
  margin: 0px 15px;
    list-style-type: none;
    color: #082032;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
    position: relative;
    transition: 0.5s;
}
.rw_navul>li::after{
content: "";
position: absolute;
left: 50%;
bottom: 0%;
width: 0%;
height: 3px;
background-color: #FFC045;
border-radius: 0px 50px 50px 0px;
transition: 0.5s;

}

.rw_navul>li::before{
    content: "";
    position: absolute;
    right: 50%;
    bottom: 0%;
    width: 0%;
    height: 3px;
    background-color: #FFC045;
    border-radius: 50px 0px 0px 50px;
    transition: 0.5s;
    
    }

    .rw_navul>li:hover::after{
        width: 50%;
    }
    .rw_navul>li:hover::before{
        width: 50%;
    }

    .rw_secondnavlink{
        color: #082032;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        text-decoration: none;
        
    }

.rw_navlinks{
    color: #082032;
    text-decoration: none;
  
    letter-spacing: 1px;
    transition: 0.5s;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.rw_navlinks:hover{
    color: #FFC045;
}
.bpr_dorpdownul{
    padding-left: 0px;
}

.bpr_dorpdownul>li{
    list-style-type: none;
    padding: 5px 0px;
    width: 200px;
    text-align: center;
    transition: 0.5s;
   
}

.bpr_dorpdownul>li:hover{
    background-color: #FFC045;
}




.bpr_maindropdownnav{
    position: relative;
}

.rw_navservicedrop{
   padding: 10px 10px;
    position: absolute;
    display: none;
    left: -70%;
    z-index: 10;
    background-color: #F6E9E9;
   
  
}

.bpr_maindropdownnav:hover .rw_navservicedrop{
    display: block;
    animation: dropdownani 0.5s linear 0s 1 forwards;
}

@keyframes dropdownani  {
    0%{
        opacity: 0;
        transform: translateY(30px);
    }
    100%{
        opacity: 1;
        transform: translate(0px);
    }
}


.rw_navul2{
margin-left: auto;
    display: flex;
    margin-bottom: 0px;
    flex-direction: row;
}

.rw_navul2>li{
    list-style-type: none;
    margin: 0px 10px;
    
}

.rw_navlinks2{
   color: #082032;
   text-decoration: none;
   font-weight: 600;
   transition: 0.5s;
}

.rw_navlinks2:hover{
    color: #FFC045;
}

.header-scrolled{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:999;
    background-color: #F6E9E9;
    box-shadow: .5084rem 1.1419rem 2.5rem 0 rgb(0 0 0 / 8%);
    transition:0.3s ease-in-out;
}

.rw_bars{
    display: none;
}


.rw_barsclose{
    display: none;
}

.rw_navsdatas{
    height: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
}



@media screen and (max-width:767px) {
    nav{
        display: block;
    }


    .rw_navsdatas{
        display: none;
        transition: 0.5s;
    }
    .rw_navul{
        display: block;
        padding-left: 0px;
        padding-top: 10px;
        transition: 0.5s;
    }

    .rw_navul>li{
        margin: 0px 0px;
        padding: 15px;
        text-align: center;
    }

    .rw_logonavimglogo{
        width: 50%;
    }

    .rw_navul2{
        padding-left: 0px;
        padding-top: 20px;

    }

    .rw_logonav{
        position: relative;
        width: 100%;
        display: block;
    }

    .rw_bars{
        display: inline;
        position: absolute;
        right: 2%;
        top: 10%;
        font-size: 25px;
        color: #FFC045;
        font-weight: 700;
    }

    .rw_barsclose{
        display: none;
        position: absolute;
        right: 2%;
        top: 10%;
        font-size: 25px;
        color: #FFC045;
        font-weight: 700;
    }

    .rw_navservicedrop{
        padding: 10px 10px;
         position: absolute;
         display: none;
         left: 10%;
         right: 10%;
         z-index: 10;
        
         box-shadow: 0 15px 40px -10px rgba(0, 0, 0, 0.3);
         background-color: #F6E9E9;
       
     }
     
     .bpr_maindropdownnav:hover .rw_navservicedrop{
         display: block;
         animation: dropdownani 0.5s linear 0s 1 forwards;
     }
     
     @keyframes dropdownani  {
         0%{
             opacity: 0;
             transform: translateY(30px);
         }
         100%{
             opacity: 1;
             transform: translate(0px);
         }
     }

     .bpr_dorpdownul>li{
        display: block;
    width: 100%;
        text-align: center;
     }
     
     .rw_navul>li:hover::after{
       display: none;
    }
    .rw_navul>li:hover::before{
       display: none;
    }
}



